import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import BlogPost from "common/components/BlogPost";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import { Section, SectionHeading, FeatureWrapper } from "./features.style";

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      saasJson {
        features {
          id
          title
          desc
          icon {
            publicURL
          }
        }
      }
    }
  `);
  return (
    <Section id="services">
      <Container noGutter>
        <SectionHeading>
          <Heading content="Services" />
          <Text content="Our core services work together to improve operations while FIGHTING unfair insurance reimbursements." />
        </SectionHeading>
        <FeatureWrapper>
          {data?.saasJson?.features?.map((feature) => (
            <BlogPost
              className="feature-item"
              key={feature.id}
              thumbUrl={feature.icon.publicURL}
              title={feature.title}
              excerpt={feature.desc}
            />
          ))}
        </FeatureWrapper>
      </Container>
    </Section>
  );
};

export default Features;
