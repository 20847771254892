const colors = {
  transparent: "transparent", // 0
  black: "#000000", // 1
  white: "#ffffff", // 2
  textPrimary: "#0F2137",
  text: "#343D48", // 3
  labelColor: "#767676", // 4
  inactiveField: "#f2f2f2", // 5
  inactiveButton: "#b7dbdd", // 6
  inactiveIcon: "#EBEBEB", // 7
  primary: "#0A2D4C", // 8
  primaryHover: "#0A2D4C", // 9
  secondary: "#0267AC", // 10
  secondaryHover: "#0267AC", // 11
  yellow: "#fdb32a", // 12
  yellowHover: "#F29E02", // 13
  borderColor: "#1b1e25", // 14
  primaryBoxShadow: "0px 8px 20px -6px rgba(42, 162, 117, 0.57)",
  secondaryBoxShadow: "0px 8px 20px -6px rgba(237, 205, 55, 0.5)",
  success: "#00C851",
  successDark: "#007E33",
};

export default colors;
