import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import { ic_chevron_right } from "react-icons-kit/md/ic_chevron_right";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Link from "common/components/Link";
import Text from "common/components/Text";
import GatsbyImage from "common/components/GatsbyImage";

import {
  Section,
  ContentWrapper,
  Illustration,
  TextContent,
} from "./advancedAnalytics.style";

const AdvancedAnalytics = () => {
  const data = useStaticQuery(graphql`
    query {
      analytics: file(relativePath: { eq: "image/analytics.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 716
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section>
      <Container>
        <ContentWrapper>
          <TextContent>
            <Heading
              className="title"
              content="Why are treatment facilities shutting down while addiction sky rockets?"
            />
            <Text
              as="span"
              className="slogan"
              content="The industry is highly fragmented."
            />
            <Text
              as="span"
              className="desc"
              content="97% of facilities are not equipped to handle the "
            />
            <Text
              as="span"
              className="desc emphasized"
              content="predatory reimbursement practices"
            />
            <Text
              as="span"
              className="desc"
              content=" of large insurance companies."
            />
          </TextContent>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default AdvancedAnalytics;
