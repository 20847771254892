import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Image from "common/components/Image";
import Link from "common/components/Link";

import {
  Section,
  ContentWrapper,
  Copyright,
  Nav,
  SocialProfiles,
  Icons,
} from "./footer.style";

import logoDark from "common/assets/image/logo-black.png";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      saasJson {
        footerNav {
          id
          url
          title
        }
        socialLinks {
          id
          link
          label
          icon {
            publicURL
          }
        }
      }
    }
  `);
  return (
    <Section>
      <Container>
        <ContentWrapper>
          <Copyright>
            <figure>
              <Image src={logoDark} alt="logo" />
            </figure>
            <p>
              Copyright © 2023 <Link href="#">L2 Insights</Link>
            </p>
          </Copyright>
          <Nav>
            {data?.saasJson?.footerNav?.map((item) => (
              <li key={item.id}>
                <Link href={item.url}>{item.title}</Link>
              </li>
            ))}
          </Nav>
          <SocialProfiles>
            <span>Social</span>
            <Icons>
              {data?.saasJson?.socialLinks?.map((item) => (
                <Link key={item.id} href={item.link} target="_blank">
                  <Image
                    src={item.icon.publicURL}
                    alt={item.label}
                    maxWidth="50px"
                  />
                </Link>
              ))}
            </Icons>
          </SocialProfiles>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Footer;
