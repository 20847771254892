import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Image from "common/components/Image";
import Heading from "common/components/Heading";
import { Section, SectionHeading, LogoWrapper } from "./clients.style";
import Link from "common/components/Link";

const Clients = () => {
  const data = useStaticQuery(graphql`
    query {
      saasJson {
        clients {
          id
          name
          link
          logo {
            publicURL
          }
        }
      }
    }
  `);
  return (
    <Section id="partners">
      <Container>
        <SectionHeading>
          <Heading content="Preferred Partner" />
        </SectionHeading>
        <LogoWrapper>
          {data?.saasJson?.clients?.map((client) => (
            <Link key={client.id} href={client.link} target="_blank">
              <Image
                key={client.id}
                src={client.logo.publicURL}
                alt={client.name}
              />
            </Link>
          ))}
        </LogoWrapper>
      </Container>
    </Section>
  );
};

export default Clients;
