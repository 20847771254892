import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const Section = styled.section`
  background-color: #0267ac;
  padding: 80px 0 30px;
  position: relative;
  z-index: 0;
`;

export const SectionHeading = styled.div`
  max-width: 700px;
  margin: 0 auto 90px;
  text-align: center;
  @media only screen and (max-width: 480px) {
    margin-bottom: 60px;
  }
  h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 1.33;
    letter-spacing: -0.04em;
    color: ${themeGet("colors.white")};
    @media only screen and (max-width: 1200px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 24px;
      line-height: 1.5;
    }
  }
`;

export const TabPanel = styled.div`
  margin: 0 auto 80px;
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 768px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TabPane = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  figure {
    border-radius: 32px;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      width: 60%;
    }
    @media only screen and (max-width: 480px) {
      width: 80%;
      margin: 10px;
    }
    img {
      @media only screen and (max-width: 768px) {
        width: 60%;
      }
      @media only screen and (max-width: 480px) {
        width: 80%;
      }
    }
  }
  p {
    font-weight: 700;
    font-size: 17px;
    line-height: 1.3;
    text-align: center;
    color: ${themeGet("colors.white")};
    margin-top: 20px;
    width: 200px;
    @media only screen and (max-width: 768px) {
      width: 60%;
      font-size: 2rem;
    }
    @media only screen and (max-width: 480px) {
      width: 80%;
      font-size: 2rem;
      margin-top: 10px;
    }
  }

  .blurb {
    font-size: 0.9rem;
    font-weight: 400;
  }

  &:last-child {
    .blurb {
      margin-top: 0;
    }
  }
`;

export const Illustration = styled.figure``;
