import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Text from "common/components/Text";
import { Section, SectionHeading, TabPanel, TabPane } from "./dashboard.style";

const Dashboard = () => {
  const data = useStaticQuery(graphql`
    query {
      dashboard: file(relativePath: { eq: "image/dashboard.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      saasJson {
        dashboardProcess {
          id
          title
          value
          blurb
          icon {
            publicURL
          }
        }
      }
    }
  `);
  return (
    <Section id="dashboard">
      <Container>
        <SectionHeading>
          <Heading content="For the last four years, we've been developing dashboards, automated reports, and AI trend indicators similar to those used by a Fortune 5 healthcare company." />
        </SectionHeading>
        <TabPanel>
          {data?.saasJson?.dashboardProcess?.map((item) => (
            <TabPane key={item.id}>
              <figure>
                <Image src={item.icon.publicURL} alt={item.title} />
              </figure>
              <Text content={item.value} />
              <Text content={item.title} />
              <Text content={item.blurb} className="blurb" />
            </TabPane>
          ))}
        </TabPanel>
      </Container>
    </Section>
  );
};

export default Dashboard;
