import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Text from "common/components/Text";
import "swiper/css/bundle";
import {
  Section,
  SectionHeading,
  ContentWrapper,
  Item,
} from "./testimonials.style";

const renderTeamMember = (item, notFirst) => (
  <Item key={item.id} className={notFirst ? undefined : "first"}>
    <div className="author-info">
      <figure>
        <Image src={item.photo.publicURL} alt={item.name} />
      </figure>
      <div className="info">
        <Heading as="h4" content={item.name} />
        <Text content={item.designation} />
        {!notFirst && <Text content={item.text} className="blurb" />}
      </div>
    </div>
  </Item>
);

const Testimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      saasJson {
        testimonials {
          id
          name
          designation
          text
          photo {
            publicURL
          }
        }
      }
    }
  `);
  return (
    <Section id="testimonials">
      <Container>
        <SectionHeading>
          <Heading content="Our team is led by entrepreneurs from leading healthcare companies." />
          <Text content="Together, we make data useful." />
        </SectionHeading>
        <ContentWrapper>
          {data?.saasJson?.testimonials?.map(renderTeamMember)}
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Testimonial;
