import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Text from "common/components/Text";
import GatsbyImage from "common/components/GatsbyImage";
import {
  Section,
  ContentWrapper,
  TextContent,
  Illustration,
  ButtonGroup,
} from "./banner.style";

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/banner/data-mountains.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 974
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section id="home">
      <Container>
        <ContentWrapper>
          <TextContent>
            <Heading content="Harnessing Healthcare Data" />
            <Text content="for substance abuse and mental health treatment" />
          </TextContent>
        </ContentWrapper>
      </Container>
      <Illustration>
        <GatsbyImage
          src={
            (data.illustration !== null) | undefined
              ? data.illustration.childImageSharp.gatsbyImageData
              : {}
          }
          alt="Illustration"
        />
      </Illustration>
    </Section>
  );
};

export default Banner;
