import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const Section = styled.section`
  padding: 70px 0;
  @media only screen and (max-width: 768px) {
    padding: 60px 0 50px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0 40px;
  }
`;

export const SectionHeading = styled.div`
  margin: 0 auto 100px;
  max-width: 575px;
  text-align: center;
  @media only screen and (max-width: 1200px) {
    margin-bottom: 75px;
  }
  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.29;
    letter-spacing: -0.5px;
    color: ${themeGet("colors.textPrimary")};
    @media only screen and (max-width: 480px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 16px;
    line-height: 2.19;
    color: ${themeGet("colors.text")};
    @media only screen and (max-width: 480px) {
      line-height: 1.9;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 400px;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    max-width: 60%;
    img {
      margin-right: 60px;
    }
  }
  @media only screen and (max-width: 480px) {
    img {
      margin-right: 30px;
    }
  }
`;
