import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Input from "common/components/Input";
import {
  Section,
  ContentWrapper,
  SubscriptionWrapper,
  SubscriptionForm,
} from "./subscription.style";

const Subscription = () => {
  const [userEmail, setUserEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    fetch("https://getform.io/f/e5911986-d515-4705-a55e-1c1a170b5889", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then(() => {
        setUserEmail("");
        setSubmitted(true);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Section id="contact-form">
      <Container>
        <ContentWrapper>
          <SubscriptionWrapper>
            <Heading content="Get in touch!" />
            <SubscriptionForm onSubmit={handleSubmit}>
              <Input
                type="email"
                name="email"
                className="input-field"
                placeholder="Type your e-mail"
                value={userEmail}
                onChange={(value) => {
                  setUserEmail(value);
                }}
              />
              {/* <!-- add hidden Honeypot input to prevent spams --> */}
              <input
                type="hidden"
                name="_gotcha"
                style={{ display: "none !important" }}
              />
              <Button
                type="submit"
                title={submitted ? "Submitted!" : "Submit"}
                icon={
                  submitted ? undefined : <Icon size={18} icon={arrowRight} />
                }
                className={submitted ? "success" : undefined}
              />
            </SubscriptionForm>
          </SubscriptionWrapper>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Subscription;
