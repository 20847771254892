import { Modal } from "@redq/reuse-modal";
import "@redq/reuse-modal/es/index.css";
import { ResetCSS } from "common/assets/css/style";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { theme } from "common/theme";
import Seo from "components/seo";
import AdvancedAnalytics from "containers/AdvancedAnalytics";
import Banner from "containers/Banner";
import Clients from "containers/Clients";
import Dashboard from "containers/Dashboard";
import Features from "containers/Features";
import Footer from "containers/Footer";
import Navbar from "containers/Navbar";
import { ContentWrapper, GlobalStyle } from "containers/app.style";
import Statistics from "containers/Statistics";
import Subscription from "containers/Subscription";
import Testimonial from "containers/Testimonial";
import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Seo title="L2 Insights" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="is-sticky">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <Statistics />
          <AdvancedAnalytics />
          <Dashboard />
          <Features />
          <Clients />
          <Testimonial />
          <Subscription />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default App;
